.sign-container {
  padding: 0 30px;
  width: 100%;
}

.signup-container{
  padding: 10px 30px 10px 30px;
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
}

.fading-in {
  animation: fadeInAnimation ease 0.05s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    margin: 0 600px 0 -600px;
  }
  100% {
    margin-left: 0;
  }
}

.ant-card {
  /* Add shadows to create the "card" effect */
  box-shadow: -1px 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.ant-card:hover {
  box-shadow: -1px 1px 8px 0 rgba(0, 0, 0, 0.2);
}

.action-buttons {
  padding: 0.4em;
  font-size: 110%;
  background-color: rgb(247, 247, 247);
  margin-right: 10px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.ant-table table {
  font-size: 0.8em;
}

.logout-button {
  font-size: 129%;
  color: rgb(255, 99, 99);
  font-weight: 900;
  padding: 10px;
  border-radius: 16%;
  margin-top: 12px;
  cursor: pointer;
  box-shadow: rgb(113 113 113) 0px 0px 1px 2px;
  background-color: white;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.role-avatar {
  display: flex;
  flex-direction: row;
  justify-content: end;
  color: #c7c7c7;
  font-size: 0.8em;
}

.name-avatar {
  color: rgb(255, 255, 255);
  margin-left: 1em;
  font-size: 1.15em;
  margin-top: 0.6em;
}

.ant-btn-primary {
  border-color: #2b6e99;
  background: #2b6e99;
}

.ant-menu-item-selected {
  background-color: #6890aa;
}

.ant-table-tbody > tr > td, .ant-table-thead > tr > th{
  white-space: inherit;
}


/* Iphone-SE */
@media (min-width:414px) and (max-width:896px){
  .signup-container{
    padding-bottom: 30vh;
    
  }
}

/* Iphone-12 Pro and pixel-5 */
@media (min-width:390px) and (max-width:844px){
  .signup-container{
    padding-bottom: 28vh;
    
  }
}

/* Samsung galaxy x8 */
@media (min-width:360px) and (max-width:740px){
  .signup-container{
    padding-bottom: 30vh;
    
  }
}

/* Ipad Air */
@media (min-width:820px) and (max-width:1180px){
  .signup-container{
    padding-bottom: 35vh;
    
  }
}

/* Ipad Air */
@media (min-width:768px) and (max-width:1024px){
  .signup-container{
    padding-bottom: 37vh;
    
  }
}